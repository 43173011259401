import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo
} from "react";

import { AuthenticationContext } from "../../../../contexts/authentication-context";
import { CustomerContext } from "../../../../contexts/customer-context";
import {
  ActivityWithFlowControlModel,
  SiteProjectsClient
} from "../../../../domain/client-customer";
import { ComponentSpecificKeys } from "../../../../translation/dictionary-keys";
import ActivitiesTileView from "./activities-tile-view";

type Props = {
  siteProjectId: string;
};

const ActivitiesTileComponent = (props: Props): React.ReactElement => {
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );
  const { currentCustomer } = useContext(CustomerContext);
  const [errorMessage, setErrorMessage] = useState<ComponentSpecificKeys>();
  const [activities, setActivities] = useState<ActivityWithFlowControlModel[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);

  const getActivities = useCallback(async () => {
    const customerIdentifier =
      currentCustomer && currentCustomer.HeaderIdentifier;
    if (!customerIdentifier) {
      return setErrorMessage(ComponentSpecificKeys.CustomersErrorMessageTitle);
    }
    setLoading(true);
    new SiteProjectsClient(await getCustomerConfigurationProvider())
      .getActivitiesForSiteProject(props.siteProjectId, customerIdentifier)
      .then((fetchedActivities) => {
        setActivities(fetchedActivities);
      })
      .catch(() => {
        setErrorMessage(ComponentSpecificKeys.ActivitiesErrorMessageTitle);
      })
      .finally(() => setLoading(false));
  }, [currentCustomer, getCustomerConfigurationProvider, props.siteProjectId]);

  useEffect(() => {
    getActivities();
  }, [getActivities]);

  const currentOrdinal = useMemo<number>(() => {
    if (activities.length < 1) return 1;
    return (
      activities.find((a) => !a.CompletedDate)?.Ordinal ??
      activities[activities.length - 1].Ordinal ??
      1
    );
  }, [activities]);

  const currentActivities = useMemo<ActivityWithFlowControlModel[] | undefined>(
    () =>
      activities.filter((a) => a.Ordinal === currentOrdinal) ??
      activities.filter((a, i) => i === activities.length - 1),
    [activities, currentOrdinal]
  );

  const onHandleRetry = (): void => {
    setErrorMessage(undefined);
    getActivities();
  };

  return (
    <ActivitiesTileView
      onRetry={onHandleRetry}
      siteProjectId={props.siteProjectId}
      activities={activities}
      errorMessage={errorMessage}
      currentActivities={currentActivities}
      currentOrdinal={currentOrdinal}
      loading={loading}
    />
  );
};

export default ActivitiesTileComponent;
