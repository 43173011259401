import React, { useMemo } from "react";

import { Tile } from "@react-gcc-eds/core";
import moment from "moment";
import { Link } from "react-router-dom";

import { InstallationSitesOverviewViewModel } from "../../../domain/client-customer";
import {
  ComponentSpecificKeys,
  InstallationSpecificKeys,
  NamespaceKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";
import TitleValuePair from "../../common/layout/title-value-pair";

type Props = {
  site: InstallationSitesOverviewViewModel;
};

const MaterialInstallationListItem = ({ site }: Props): React.ReactElement => {
  const { translate } = useTranslation();

  const installActions = useMemo<JSX.Element>(
    () => (
      <Link
        key="install-action"
        to={`/materialinstallation/${site.SiteProjectId}`}
        className="btn primary"
      >
        {translate(
          NamespaceKeys.ComponentSpecific,
          ComponentSpecificKeys.InstallAction
        )}
      </Link>
    ),
    [site.SiteProjectId, translate]
  );

  const startDate: JSX.Element = useMemo<JSX.Element>(() => {
    return (
      <TitleValuePair
        title={translate(
          NamespaceKeys.InstallationSpecific,
          InstallationSpecificKeys.StartDate
        )}
        value={<>{moment(site.StartDate).format("LL") ?? ""}</>}
      />
    );
  }, [site.StartDate, translate]);

  return (
    <>
      <Tile
        className="overview-list-item"
        title={site.SiteName}
        subtitle={site.CompanyName}
      >
        <div className="overview-content">{startDate}</div>
        <div className="actions">{installActions}</div>
      </Tile>
    </>
  );
};

export default MaterialInstallationListItem;
