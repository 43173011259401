import React, { useState, useEffect, useContext, useCallback } from "react";

import { AuthenticationContext } from "../../../../../contexts/authentication-context";
import { CustomerContext } from "../../../../../contexts/customer-context";
import {
  ActivityWithFlowControlModel,
  SiteWalkClient
} from "../../../../../domain/client-customer";
import { UserRoles } from "../../../../../domain/enums";
import {
  ActivitySpecificKeys,
  ComponentSpecificKeys,
  NamespaceKeys
} from "../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../translation/translation-utils";
import { utcDateToLocal } from "../../../../../utils/utils";
import { ActivityComponentStatus } from "../activity-component";
import SiteWalkActivityModel from "./site-walk-activity-model";
import SiteWalkActivityView from "./site-walk-activity-view";

type Props = {
  siteProjectId: string;
  activity: ActivityWithFlowControlModel;
  onReloadActivities: () => void;
  status: ActivityComponentStatus;
};

const SiteWalkActivityComponent = (props: Props): React.ReactElement => {
  const { translate } = useTranslation();
  const [isSendingForApproval, setIsSendingForApproval] =
    useState<boolean>(false);
  const [activity, setActivity] = useState<SiteWalkActivityModel>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { currentUser, currentCustomer } = useContext(CustomerContext);
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );

  useEffect(() => {
    if (!activity && currentUser) {
      setActivity({
        ...props.activity,
        proposedLocalDate:
          props.activity.ProposedDate &&
          utcDateToLocal(props.activity.ProposedDate),
        scheduledLocalDate:
          props.activity.ScheduledDate &&
          utcDateToLocal(props.activity.ScheduledDate),
        completedLocalDate:
          props.activity.CompletedDate &&
          utcDateToLocal(props.activity.CompletedDate),
        readyForApprovalLocalDate:
          props.activity.ReadyForApprovalDate &&
          utcDateToLocal(props.activity.ReadyForApprovalDate),
        isReadyToSendForApproval:
          !!props.activity.ScheduledDate &&
          !props.activity.ReadyForApprovalDate,
        currentUserIsAbleToSendForApproval:
          currentUser.Role === UserRoles.SuperUser ||
          (props.activity.ScheduledUser &&
            props.activity.ScheduledUser.Id === currentUser.Id) ||
          false
      });
    }
  }, [activity, props.activity, currentUser]);

  const onHandleSendForApproval = useCallback(async (): Promise<void> => {
    const customer = currentCustomer && currentCustomer.HeaderIdentifier;
    const activityId = activity && activity.Id;

    if (!activityId || !customer) {
      return setErrorMessage(
        translate(
          NamespaceKeys.ComponentSpecific,
          ComponentSpecificKeys.ActivitiesErrorMessageTitle
        )
      );
    }

    setIsSendingForApproval(true);
    new SiteWalkClient(await getCustomerConfigurationProvider())
      .setSiteWalkToReadyForApproval(props.siteProjectId, activityId, customer)
      .then(props.onReloadActivities)
      .catch(() => {
        setErrorMessage(
          translate(
            NamespaceKeys.ActivitySpecific,
            ActivitySpecificKeys.CouldNotSendForApproval
          )
        );
      })
      .finally(() => setIsSendingForApproval(false));
  }, [
    activity,
    currentCustomer,
    getCustomerConfigurationProvider,
    props.siteProjectId,
    props.onReloadActivities,
    translate
  ]);

  return (
    <SiteWalkActivityView
      errorMessage={errorMessage}
      activity={activity}
      isSendingForApproval={isSendingForApproval}
      onSendForApproval={onHandleSendForApproval}
      status={props.status}
    />
  );
};

export default SiteWalkActivityComponent;
