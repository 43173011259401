import React from "react";

import { ActivityWithFlowControlModel } from "../../../../../domain/client-customer";
import {
  NamespaceKeys,
  ActivitySpecificKeys
} from "../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../translation/translation-utils";
import ActivityComponent, {
  ActivityComponentStatus,
  renderUserAndDateDetails
} from "../activity-component";

type Props = {
  activity: ActivityWithFlowControlModel;
  status: ActivityComponentStatus;
};

const ActivityBaseComponent = ({
  activity,
  status
}: Props): React.ReactElement => {
  const { translate } = useTranslation();
  return (
    <ActivityComponent
      title={activity.Label}
      metadata={
        activity.CompletedDate && activity.CompletedUser
          ? renderUserAndDateDetails(
              translate(
                NamespaceKeys.ActivitySpecific,
                ActivitySpecificKeys.CompletedBy
              ),
              activity.CompletedUser,
              activity.CompletedDate
            )
          : activity.ScheduledUser && activity.ScheduledDate
          ? renderUserAndDateDetails(
              translate(
                NamespaceKeys.ActivitySpecific,
                ActivitySpecificKeys.ScheduledFor
              ),
              activity.ScheduledUser,
              activity.ScheduledDate
            )
          : activity.PlannedDate && activity.PlannedUser
          ? renderUserAndDateDetails(
              translate(
                NamespaceKeys.ActivitySpecific,
                ActivitySpecificKeys.PlannedFor
              ),
              activity.PlannedUser,
              activity.PlannedDate
            )
          : status === ActivityComponentStatus.Active &&
            translate(
              NamespaceKeys.ActivitySpecific,
              ActivitySpecificKeys.ActivityIsNotScheduled
            )
      }
      status={status}
    />
  );
};

export default ActivityBaseComponent;
