import React, { useState, useMemo } from "react";

import { Row, Loader, Tile, Button, Icon } from "@react-gcc-eds/core";

import { ActivityWithFlowControlModel } from "../../../../domain/client-customer";
import ActivityTypes from "../../../../domain/enums/activity-types";
import {
  ComponentSpecificKeys,
  NamespaceKeys,
  GeneralKeys,
  ActivitySpecificKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import CenteredContainer from "../../../common/layout/centered-container";
import ActivityBase from "./activity-base";
import { ActivityComponentStatus } from "./activity-component";
import SiteWalkActivity from "./site-walk-activity";

import "./activities-tile.scss";

type Props = {
  onRetry: () => void;
  siteProjectId: string;
  activities: ActivityWithFlowControlModel[] | undefined;
  errorMessage: ComponentSpecificKeys | undefined;
  currentActivities: ActivityWithFlowControlModel[] | undefined;
  currentOrdinal: number;
  loading: boolean;
};

const renderActivity = (
  activity: ActivityWithFlowControlModel,
  siteProjectId: string,
  active: boolean,
  reloadActivities: () => void
): React.ReactElement => {
  const status = active
    ? ActivityComponentStatus.Active
    : activity.CompletedDate
    ? ActivityComponentStatus.Completed
    : ActivityComponentStatus.Default;

  switch (activity.ActivityType) {
    case ActivityTypes.DesignSurvey:
    case ActivityTypes.SiteWalkEngineering:
      return (
        <SiteWalkActivity
          siteProjectId={siteProjectId}
          activity={activity}
          onReloadActivities={reloadActivities}
          status={status}
          key={activity.Id}
        />
      );
    default:
      return (
        <ActivityBase activity={activity} status={status} key={activity.Id} />
      );
  }
};

const ActivitiesTileView = (props: Props): React.ReactElement => {
  const { translate } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);

  const filteredActivities = useMemo(() => {
    const currentActivities = props.currentActivities;
    if (expanded || !currentActivities || currentActivities.length < 1) {
      return props.activities;
    }
    return props.activities?.filter(
      (a) =>
        a.Ordinal === props.currentOrdinal - 1 ||
        a.Ordinal === props.currentOrdinal ||
        a.Ordinal === props.currentOrdinal + 1
    );
  }, [
    expanded,
    props.activities,
    props.currentActivities,
    props.currentOrdinal
  ]);

  const hasFilteredActivities = useMemo<boolean>(() => {
    if (!props.activities || !filteredActivities) return false;
    return props.activities.some(
      (a) =>
        a.Ordinal === props.currentOrdinal - 1 ||
        a.Ordinal === props.currentOrdinal ||
        a.Ordinal === props.currentOrdinal + 1
    );
  }, [filteredActivities, props.activities, props.currentOrdinal]);

  const lastOrdinal = useMemo<number>(() => {
    const lastActivity: ActivityWithFlowControlModel | undefined =
      props.activities && props.activities[props.activities.length - 1];
    return lastActivity?.Ordinal ?? 1;
  }, [props.activities]);

  const renderedActivities = useMemo<React.ReactNode>(
    () =>
      filteredActivities?.map((activity) =>
        renderActivity(
          activity,
          props.siteProjectId,
          activity.Ordinal === props.currentOrdinal,
          props.onRetry
        )
      ) || <React.Fragment />,
    [
      filteredActivities,
      props.currentOrdinal,
      props.siteProjectId,
      props.onRetry
    ]
  );

  return (
    <Row>
      <>
        {props.errorMessage ? (
          <Tile
            title={translate(
              NamespaceKeys.ComponentSpecific,
              props.errorMessage
            )}
          >
            <p>
              {translate(
                NamespaceKeys.General,
                GeneralKeys.PleaseTryAgainLater
              )}
            </p>
            <Button className="btn-icon" onClick={props.onRetry}>
              <Icon name="reload" className="btn-icon" />
              {translate(NamespaceKeys.General, GeneralKeys.Reload)}
            </Button>
          </Tile>
        ) : props.loading ? (
          <Tile
            title={translate(
              NamespaceKeys.ActivitySpecific,
              ActivitySpecificKeys.CurrentActivity
            )}
            className="activities-tile"
          >
            <CenteredContainer>
              <Loader size="medium" />
            </CenteredContainer>
          </Tile>
        ) : (
          <Tile title="Activities" className="activities-tile hide-section">
            <div className="activities-list">
              {hasFilteredActivities &&
                props.currentOrdinal > 2 &&
                !expanded && <div className="collapse-indicator">...</div>}
              {renderedActivities}
              {hasFilteredActivities &&
                props.currentOrdinal + 2 > lastOrdinal &&
                !expanded && <div className="collapse-indicator">...</div>}
            </div>
            {hasFilteredActivities && (
              // Disabling rule because we are not controlling the jsx-a11y plugin.
              // Rule is regarding accessibility only
              // eslint-disable-next-line
              <a
                className="subtle-link"
                onClick={(): void => setExpanded((prev) => !prev)}
              >
                {expanded ? "Show less" : "Show more"}
              </a>
            )}
          </Tile>
        )}
      </>
    </Row>
  );
};

export default ActivitiesTileView;
