import React from "react";

import { Icon } from "@react-gcc-eds/core";
import moment from "moment";

import { UserTooltipModel } from "../../../../domain/client-customer";
import UserInformationButton from "../../../common/user-information-button";

export enum ActivityComponentStatus {
  Default,
  Active,
  Completed
}

export const renderUserAndDateDetails = (
  verb: string,
  user: UserTooltipModel,
  date: Date
): React.ReactElement => (
  <>
    {`${verb} `}
    <span>
      <UserInformationButton inline user={user} />
    </span>{" "}
    <span>
      <span className="time">
        <Icon name="time" />
        {moment.utc(date).local().format("LLL")}
      </span>
    </span>
  </>
);

type Props = {
  status: ActivityComponentStatus;
  title: React.ReactNode;
  metadata: React.ReactNode;
  actionColumn?: React.ReactNode;
};

const ActivityComponent = ({
  status,
  title,
  metadata,
  actionColumn
}: Props): React.ReactElement => (
  <div
    className={`activity${
      status === ActivityComponentStatus.Active
        ? " current"
        : status === ActivityComponentStatus.Completed
        ? " completed"
        : ""
    }`}
  >
    <div className="step-column">
      <div className="step" />
    </div>
    <div className="title-column">
      <div className="title">{title}</div>
      {metadata && <div className="meta-data">{metadata}</div>}
    </div>
    {actionColumn && <div className="action-column">{actionColumn}</div>}
  </div>
);

export default ActivityComponent;
