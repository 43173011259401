import React, {
  useContext,
  useCallback,
  useState,
  useLayoutEffect,
  useMemo
} from "react";

import { Loader, Card, Row, Tile, Icon, Button } from "@react-gcc-eds/core";
import { useQuery } from "react-query";
import { FixedSizeList, ListChildComponentProps } from "react-window";

import { AuthenticationContext } from "../../contexts/authentication-context";
import { CustomerContext } from "../../contexts/customer-context";
import { LayoutContext } from "../../contexts/layout-context";
import {
  SiteProjectsClient,
  SiteProjectViewModel
} from "../../domain/client-customer";
import {
  NamespaceKeys,
  MenuTitleKeys,
  ComponentSpecificKeys,
  GeneralKeys
} from "../../translation/dictionary-keys";
import { useTranslation } from "../../translation/translation-utils";
import CenteredContainer from "../common/layout/centered-container";
import SiteProjectsListFilters from "./site-project-list-filters";
import SiteProjectListItem from "./site-project-list-item";

const SiteProjectList: React.FC = () => {
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );
  const { setPageTitle } = useContext(LayoutContext);
  const { currentCustomer, currentUser } = useContext(CustomerContext);
  const { translate } = useTranslation();

  const [hideFilters, setHideFilters] = useState<boolean>(true);
  const [filteredSiteProjects, setFilteredSiteProjects] = useState<
    SiteProjectViewModel[]
  >([]);

  const { data, isLoading, isError } = useQuery(
    "siteProjectsOverview",
    async () => {
      const customerIdentifier =
        currentCustomer && currentCustomer.HeaderIdentifier;
      if (!customerIdentifier) {
        throw new Error("No customer set");
      }
      return await new SiteProjectsClient(
        await getCustomerConfigurationProvider()
      ).getSiteProjectsOverview(
        1,
        [],
        [],
        false,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        customerIdentifier
      );
    }
  );

  const siteProjects = useMemo<SiteProjectViewModel[] | undefined>(
    () => data?.Items,
    [data?.Items]
  );

  const [listContainerDomObjectRef, setListContainerDomObjectRef] =
    useState<HTMLDivElement>();
  const [listContainerHeight, setListContainerHeight] = useState<number>(0);
  const refFn: (instance: HTMLDivElement | null) => void = useCallback(
    (node) => {
      if (node) {
        setListContainerDomObjectRef(node);
        setListContainerHeight(node.getBoundingClientRect().height);
      }
    },
    []
  );

  useLayoutEffect(() => {
    listContainerDomObjectRef &&
      setListContainerHeight(
        listContainerDomObjectRef.getBoundingClientRect().height
      );
  }, [listContainerDomObjectRef, hideFilters]);

  const handleToggleFilterClick = useCallback(() => {
    setListContainerHeight(0);
    setHideFilters((prev) => !prev);
  }, []);

  const handleOnFiltersChanged = useCallback(
    (filteredList: SiteProjectViewModel[]) => {
      setFilteredSiteProjects(filteredList);
      const titleBase = translate(
        NamespaceKeys.MenuTitles,
        MenuTitleKeys.SiteProjectsMenuTitle
      );
      titleBase &&
        setPageTitle &&
        (isLoading
          ? setPageTitle({ title: titleBase })
          : setPageTitle({
              title: `${titleBase} (${filteredList.length})`,
              actions: (
                <div className="action">
                  <div className="dropdown more">
                    <Button onClick={handleToggleFilterClick}>
                      <Icon name="filter" />
                    </Button>
                  </div>
                </div>
              )
            }));
    },
    [handleToggleFilterClick, isLoading, setPageTitle, translate]
  );

  return isLoading ? (
    <CenteredContainer>
      <Loader size="large" />
    </CenteredContainer>
  ) : isError ? (
    <Card
      title={translate(
        NamespaceKeys.ComponentSpecific,
        ComponentSpecificKeys.SiteProjectsErrorMessageTitle
      )}
      content={translate(
        NamespaceKeys.General,
        GeneralKeys.PleaseTryAgainLater
      )}
    />
  ) : (
    <div className="overview-list">
      {currentUser && (
        <SiteProjectsListFilters
          hidden={hideFilters}
          currentUser={currentUser}
          unfilteredList={siteProjects}
          onFiltersChanged={handleOnFiltersChanged}
        />
      )}
      {!filteredSiteProjects.length ? (
        siteProjects && siteProjects.length ? (
          <div>
            <Tile
              title={translate(
                NamespaceKeys.ComponentSpecific,
                ComponentSpecificKeys.NoSiteProjectsWithFiltersTitle
              )}
            >
              <p>
                {translate(
                  NamespaceKeys.ComponentSpecific,
                  ComponentSpecificKeys.NoSiteProjectsWithFiltersMessageOne
                )}
                <Icon name="filter" />
                {translate(
                  NamespaceKeys.ComponentSpecific,
                  ComponentSpecificKeys.NoSiteProjectsWithFiltersMessageTwo
                )}
              </p>
            </Tile>
          </div>
        ) : (
          <div>
            <Tile
              title={translate(
                NamespaceKeys.ComponentSpecific,
                ComponentSpecificKeys.NoSiteProjectsTitle
              )}
            >
              <p>
                {translate(
                  NamespaceKeys.ComponentSpecific,
                  ComponentSpecificKeys.NoSiteProjectsMessage,
                  {
                    customerName: currentCustomer && currentCustomer.DisplayName
                  }
                )}
              </p>
            </Tile>
          </div>
        )
      ) : (
        <Row className="list-row">
          <div ref={refFn} className="list-container">
            <FixedSizeList
              height={listContainerHeight}
              width="100%"
              itemCount={filteredSiteProjects.length}
              itemSize={230}
            >
              {({
                index,
                style
              }: ListChildComponentProps): React.ReactElement => (
                <div style={style} className="overview-item">
                  <SiteProjectListItem
                    siteProject={filteredSiteProjects[index]}
                  />
                </div>
              )}
            </FixedSizeList>
          </div>
        </Row>
      )}
    </div>
  );
};

export default SiteProjectList;
