import React from "react";

import { Button, Icon } from "@react-gcc-eds/core";
import moment from "moment";

import {
  ActivitySpecificKeys,
  NamespaceKeys
} from "../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../translation/translation-utils";
import ActivityComponent, {
  ActivityComponentStatus,
  renderUserAndDateDetails
} from "../activity-component";
import SiteWalkActivityModel from "./site-walk-activity-model";

type Props = {
  errorMessage: string | undefined;
  activity: SiteWalkActivityModel | undefined;
  isSendingForApproval: boolean;
  status: ActivityComponentStatus;
  onSendForApproval: () => void;
};

const SiteWalkActivityView = (props: Props): React.ReactElement | null => {
  const { translate } = useTranslation();
  return (
    <ActivityComponent
      title={props.activity?.Label}
      status={props.status}
      metadata={
        props.activity?.CompletedDate && props.activity.CompletedUser ? (
          renderUserAndDateDetails(
            translate(
              NamespaceKeys.ActivitySpecific,
              ActivitySpecificKeys.CompletedBy
            ),
            props.activity.CompletedUser,
            props.activity.CompletedDate
          )
        ) : props.activity?.readyForApprovalLocalDate ? (
          <>
            {translate(
              NamespaceKeys.ActivitySpecific,
              ActivitySpecificKeys.SentForApprovalAt
            )}{" "}
            <span>
              <span className="time">
                <Icon name="time" />
                {moment
                  .utc(props.activity.ReadyForApprovalDate)
                  .local()
                  .format("LLL")}
              </span>
            </span>
          </>
        ) : props.activity?.ScheduledDate && props.activity.ScheduledUser ? (
          renderUserAndDateDetails(
            translate(
              NamespaceKeys.ActivitySpecific,
              ActivitySpecificKeys.ScheduledFor
            ),
            props.activity.ScheduledUser,
            props.activity.ScheduledDate
          )
        ) : props.activity?.PlannedDate && props.activity.PlannedUser ? (
          renderUserAndDateDetails(
            translate(
              NamespaceKeys.ActivitySpecific,
              ActivitySpecificKeys.PlannedFor
            ),
            props.activity.PlannedUser,
            props.activity.PlannedDate
          )
        ) : (
          props.status === ActivityComponentStatus.Active &&
          translate(
            NamespaceKeys.ActivitySpecific,
            ActivitySpecificKeys.ActivityIsNotScheduled
          )
        )
      }
      actionColumn={
        props.activity?.isReadyToSendForApproval &&
        props.activity.currentUserIsAbleToSendForApproval && (
          <Button
            primary
            onClick={props.onSendForApproval}
            className={props.isSendingForApproval ? "loading" : undefined}
          >
            {!props.isSendingForApproval &&
              translate(
                NamespaceKeys.ActivitySpecific,
                ActivitySpecificKeys.SendForApproval
              )}
          </Button>
        )
      }
    />
  );
};

export default SiteWalkActivityView;
